import * as React from "react"
import Post from "../../../components/post"
import {postMapper} from "../utils/postMapper";
require(`katex/dist/katex.min.css`)

type Props = {
    data: {
        contentfulPerfectPost: any
        [key: string]: any
    }
    [key: string]: any
}

export default function MinimalBlogCorePost({...props}: Props) {
    const {data: {contentfulPerfectPost}} = props;

    if(!contentfulPerfectPost) return null; // TODO

    const post = postMapper(contentfulPerfectPost);

    console.log(props, post)

    return <Post post={post} />
}
