import { graphql } from "gatsby"
import PostComponent from "../components/post"

export default PostComponent

export const query = graphql`
  query($slug: String!) {
    contentfulPerfectPost(slug: { eq: $slug }) {
      # slug
      title
      date(formatString: "MMMM Do, YYYY")
      tags
      description {
        id
      }
      # canonicalUrl
      body {
        childMdx {
          body
        }
      }
      timeToRead
    }
  }
`