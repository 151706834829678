/** @jsx jsx */
import {MDXRenderer} from "gatsby-plugin-mdx"
import * as React from "react"
import Layout from "./layout"
import ItemTags from "./item-tags"
import SEO from "./seo"
import {Link} from "gatsby";
import { Box, Flex } from "@theme-ui/components"
import { jsx, Link as TLink, Heading } from "theme-ui"

type PostProps = {
  post: {
    slug: string
    title: string
    date: string
    tags?: {
      name: string
      slug: string
    }[]
    description?: string
    canonicalUrl?: string
    body: {
      childMdx: {
        body: string
      }
    }
    timeToRead?: number
    banner?: {
      childImageSharp: {
        resize: {
          src: string
        }
      }
    }
  }
} & any;

const px = [`32px`, `16px`, `8px`, `4px`]
const shadow = px.map((v) => `rgba(0, 0, 0, 0.15) 0px ${v} ${v} 0px`)

const Post = ({post}: PostProps) => {

  return (
      <Layout>
        <SEO
            title={post.title}
            description={post.description ? post.description : post.excerpt}
            image={post.banner ? post.banner.childImageSharp.resize.src : undefined}
            pathname={post.slug}
            canonicalUrl={post.canonicalUrl}
        />
        <Heading as="h1" variant="styles.h1">
          {post.title}
        </Heading>
        <p sx={{color: `secondary`, mt: 3, a: {color: `secondary`}, fontSize: [1, 1, 2]}}>
          <time>{post.date}</time>
          {post.tags && (
              <React.Fragment>
                {` — `}
                <ItemTags tags={post.tags} />
              </React.Fragment>
          )}
          {post.timeToRead && ` — `}
          {post.timeToRead && <span>{post.timeToRead} min read</span>}
        </p>
        <article>
          <section
              sx={{
                my: 5,
                ".gatsby-resp-image-wrapper": {my: [4, 4, 5], boxShadow: shadow.join(`, `)},
                // ".gatsby-resp-image-background-image": { opacity: '1 !important' },
                variant: `layout.content`,
              }}
          >
            <MDXRenderer>{post.body.childMdx.body}</MDXRenderer>
          </section>
        </article>


        <section
            sx={{
              variant: `dividers`,
            }}
        >
          <p>
            Dzięki za przeczytanie 😊 <br/>Mam nadzieję że masz coś z tego dla siebie. <br/><br/>Do zobaczenia!
          </p>
        </section>
      </Layout>
  );
}

export default Post
